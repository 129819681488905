import React from 'react';
import { object } from 'prop-types';
import root from 'window-or-global';
import loadable from '@loadable/component';

const SubscriptionFactory = loadable(() => import('components/subscription-factory'));
const BuySubscription = loadable(() => import('components/subscription/buy'));

const Grid = loadable(() => import('@material-ui/core/Grid'));

const SilverSubscriptionView = ({ subscriptionPrices }) => {
  const {
    silver = {},
  } = subscriptionPrices;

  return (
    <Grid spacing={1}
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
     >
      <Grid item xs={12} md={10}>
      <span id="benefits" />
      <SubscriptionFactory{...{
        subscription: 'silver',
        collapsed: true,
        models: subscriptionPrices,
        onSelect: () => {
          setTimeout(() => {
            const elmnt = root.document.getElementById('benefits');
            if (elmnt) {
              elmnt.scrollIntoView({ behavior: 'smooth', inline: 'start' });
            }
          }, 100);
        },
      }}/>
    <BuySubscription model={silver}/>
    </Grid>
  </Grid>
  );
};

SilverSubscriptionView.propTypes = { subscriptionPrices: object.isRequired };

export default SilverSubscriptionView;
